<template>
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->

        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Entries</label>
          <v-select
            v-model="pagination.perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="pagination.perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <b-button
            variant="primary"
            @click="
              $router.push({
                name: tag_type.tag_type_name + '-form',
                params: {
                  tag_id: 'new',
                  tag_type_name: tag_type.tag_type_name,
                },
              })
            "
          >
            Add {{ tag_type.tag_type_description }}
          </b-button>
        </b-col>

        <b-col sm="12" md="6">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              class="d-inline-block mr-1"
              placeholder="Search..."
              v-model="keyword"
              type="text"
            />
          </div>
        </b-col>

        <!-- Search -->
        <!-- <b-col cols="12" md="6">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="filters.search"
              class="d-inline-block mr-1"
              placeholder="Search..."
            />
            <v-select
              v-model="filters.statusFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              class="invoice-filter-select"
              placeholder="Select Status"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select>
          </div>
        </b-col> -->
      </b-row>
    </div>

    <b-table
      ref="refTagList"
      :items="tagsList"
      responsive
      :fields="tableColumns"
      :per-page="pagination.perPage"
      :current-page="pagination.currentPage"
      primary-key="tag_id"
      show-empty
      empty-text="No matching records found"
      class="position-relative"
    >
      <template #cell(index)="data">
        {{ tags_list.indexOf(data.item) + 1 }}
      </template>

      <template #cell(tag_name)="data">
        {{ data.item.tag_name }}
        <span v-if="data.item.tag_description != null">
          | {{ data.item.tag_description }}
        </span>
        <span v-if="data.item.tag_cat_title != null">
          | Title: {{ data.item.tag_cat_title }}
        </span>
        <span v-if="data.item.tag_cat_percent != null">
          | Percentage: {{ data.item.tag_cat_percent }}%
        </span>
      </template>

      <!-- Column: Actions -->
      <template #cell(edit)="data">
        <div class="text-nowrap">
          <feather-icon
            :id="`tag-row-${data.item.tag_id}-edit-icon`"
            icon="EditIcon"
            size="16"
            class="mx-1"
            @click="
              $router.push({
                name: tag_type.tag_type_name + '-form',
                params: {
                  tag_id: data.item.tag_id,
                  tag_type_name: tag_type.tag_type_name,
                },
              })
            "
          />
          <b-tooltip
            :title="'Edit ' + tag_type.tag_type_description"
            :target="`tag-row-${data.item.tag_id}-edit-icon`"
          />
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="
            d-flex
            align-items-center
            justify-content-center justify-content-sm-start
          "
        >
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="
            d-flex
            align-items-center
            justify-content-center justify-content-sm-end
          "
        >
          <b-pagination
            v-model="pagination.currentPage"
            :total-rows="tags_list.length"
            :per-page="pagination.perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import MasterServices from "@/apiServices/MasterServices";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    vSelect,
  },
  computed: {
    /* tagsList() {
      return this.keyword
        ? this.tags_list.filter((x) => x.tag_name.includes(this.keyword))
        : this.tags_list;
    }, */

    tagsList() {
      return this.keyword
        ? this.tags_list.filter((item) => {
            if (item.tag_name !== null && item.tag_name !== undefined) {
              if (
                item.tag_name
                  .toString()
                  .toLowerCase()
                  .includes(this.keyword.toString().toLowerCase())
              ) {
                return true;
              } else {
                return false;
              }
            }
          })
        : this.tags_list;
    },
  },
  watch: {
    "$route.params.tag_type_name": {
      handler: function () {
        this.beforeMountFun();
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      tag_type: {},
      tableColumns: [
        { key: "index", label: "#", sortable: false },
        { key: "tag_name", label: "Name", sortable: false },
        { key: "edit", label: "Edit", sortable: false },
      ],
      tags_list: [],
      pagination: {
        currentPage: 1,
        perPage: 10,
        perPageOptions: [5, 10, 20, 30],
      },
      filters: {
        search: null,
      },
      keyword: "",
    };
  },
  methods: {
    async getTagsList(tag_type_name) {
      try {
        const response = await MasterServices.getTags({ tag_type_name });
        if (response.data.status) {
          this.tags_list = response.data.data;
          console.log(this.tags_list);
        }
        console.log("getTags: ", this.tags_list);
      } catch (err) {
        console.log("Error in getting all tags ", err);
      }
    },
    async getTagTypeDetails(tag_type_name) {
      try {
        const response = await MasterServices.getTagTypeDetails({
          tag_type_name,
        });
        if (response.data.status) {
          this.tag_type = response.data.data;
        }
      } catch (err) {
        console.log("Error in getTagTypeDetails ", err);
      }
    },
    beforeMountFun() {
      const { tag_type_name } = this.$route.params;
      console.log("TAG_NAME: ", tag_type_name);
      if (tag_type_name) {
        this.getTagsList(tag_type_name);
        this.getTagTypeDetails(tag_type_name);
      }
    },
  },
  beforeMount() {
    this.beforeMountFun();
  },
};
</script>

<style>
</style>